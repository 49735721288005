import React,{ useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import {  useNavigate } from 'react-router-dom';
import { Typography  } from "@mui/material";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { useDispatch, useSelector } from "react-redux";
import { api } from 'common';
import Switch from "@mui/material/Switch";

export default function Sponsors() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editSponsor
  } = api;

  const columns = [
    {
      title: t('name'),
      field: 'name',
    },
    {
      title: t('email'),
      field: 'email',
    },
    {
      title: t("account_approve"),
      field: "approved",
      type: "boolean",
      render: (rowData) => (
        <Switch
          checked={rowData.approved}
          onChange={() => handleApproved(rowData)}
        />
      ),
    },
  ];

  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sponsorsData = useSelector(state => state.sponsorsdata);

  useEffect(()=>{
    if(sponsorsData.sponsors){
      setData(sponsorsData.sponsors);
    } else {
      setData([]);
    }
  }
  ,[sponsorsData.sponsors]);

  const [selectedRow, setSelectedRow] = useState(null);

  const handleApproved = (rowData) => {
    dispatch(editSponsor({ ...rowData, approved: !rowData.approved }, "Update"));
  }

  console.log(data);

  return (
    sponsorsData.loading ? <CircularLoading /> :
    <MaterialTable
      title={t('sponsors_list')}
      columns={columns}
      data={data}
      style={{
        direction: isRTL === "rtl" ? "rtl" : "ltr",
        borderRadius: "8px",
        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        padding: "20px",
      }}
      onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
      options={{
        pageSize: 10,
        pageSizeOptions: [10, 15, 20],
        exportButton: true,
        rowStyle: rowData => ({
          backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF',
          border: "1px solid rgba(224, 224, 224, 1)",
        }),
        headerStyle: {
          position: "sticky",
          top: "0px",
          backgroundColor: SECONDORY_COLOR,
          color: colors.BLACK,
          textAlign: isRTL === "rtl" ? "right" : "center", // Adjust based on RTL
          border: "1px solid rgba(224, 224, 224, 1)",
          minWidth: "70px"
        },
        cellStyle: {
          border: "1px solid rgba(224, 224, 224, 1)",
          textAlign: "center",
        },
        actionsColumnIndex: -1,
      }}
      localization={{
        body: {
          addTooltip: t('add'),
          deleteTooltip: t('delete'),
          editTooltip: t('edit'),
          emptyDataSourceMessage: t('blank_message'),
          editRow: {
            deleteText: t('delete_message'),
            cancelTooltip: t('cancel'),
            saveTooltip: t('save')
          },
        },
        toolbar: {
          searchPlaceholder: t('search'),
          exportTitle: t('export'),
        },
        header: {
          actions: t('actions')
        },
        pagination: {
          labelDisplayedRows: `{from}-{to} ${t('of')} {count}`,
          firstTooltip: t('first_page_tooltip'),
          previousTooltip: t('previous_page_tooltip'),
          nextTooltip: t('next_page_tooltip'),
          lastTooltip: t('last_page_tooltip')
        },
      }}
      editable={{
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              dispatch(editSponsor(oldData, "Delete"));
              resolve();
            }, 600);
          }),
      }}
      actions={[
        {
          icon: 'add',
          tooltip: t("add_sponsor"),
          isFreeAction: true,
          onClick: () => navigate("/sponsors/addsponsor")
        },
        (rowData) => ({
          icon: () => (
            <div style={{display:"flex", alignItems:"center" , flexWrap :"wrap", padding:10, backgroundColor:MAIN_COLOR, borderRadius:5, boxShadow: "0px 3px 5px 0px #B4B4B3"}}>
            
              <Typography style={{color:colors.LandingPage_Background}}>
              {t("banners")}
              </Typography>
              </div>
          ),
          tooltip:t('banners'),
          onClick:()=>{
            navigate(`/sponsors/${rowData.id}/banners`)
          }
        }),
        (rowData) => ({
          icon: () => (
            <div style={{display:"flex", alignItems:"center" , flexWrap :"wrap", padding:10, backgroundColor:MAIN_COLOR, borderRadius:5, boxShadow: "0px 3px 5px 0px #B4B4B3"}}>
            
              <Typography style={{color:colors.LandingPage_Background}}>
              {t("advertising_details")}
              </Typography>
              </div>
          ),
          tooltip:t('advertising_details'),
          onClick:()=>{
            navigate(`/sponsors/advertisingdetails/${rowData.id}`)
          }
        }),
        {
          icon: "info",
          tooltip: t("info"),
          onClick: (event, rowData) => {
            navigate(`/sponsors/sponsorinfo/${rowData.id}`)
          },
        },
        {
          icon: "edit",
          tooltip: t("edit"),
          onClick: (event, rowData) => {
            navigate(`/sponsors/updatesponsor/${rowData.id}`)
          },
        },
      ]}
    />
  );
}