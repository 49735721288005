import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import AlertDialog from "../components/AlertDialog";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { api } from "common";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      "&:hover fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  formCard: {
    borderRadius: "19px",
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
    maxWidth: "75vw",
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  formTitle: {
    textAlign: props => props.isRTL === 'rtl' ? "right" : "left",
    fontWeight: "bold",
    color: MAIN_COLOR,
    marginBottom: theme.spacing(2),
  },
  formSectionTitle: {
    textAlign: props => props.isRTL === 'rtl' ? "right" : "left",
    fontWeight: "bold",
    color: MAIN_COLOR,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: MAIN_COLOR,
    color: '#FFF',
    '&:hover': {
      backgroundColor: SECONDORY_COLOR,
    },
  },
}));

const AddSponsorBanner = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editBanner } = api;
  const classes = useStyles({ isRTL });
  const [bannerData, setBannerData] = useState({
    name: "",
    webUrl: "",
    map_banner: false,
    image: "https://www.solidbackgrounds.com/images/1920x1080/1920x1080-gray-solid-color-background.jpg",
    clicks: 0,
    impressions: 0,
  });
  const [loading, setLoading] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "map_banner") {
      setBannerData({ ...bannerData, [id]: e.target.checked });
    } else {
      setBannerData({ ...bannerData, [id]: value });
    }
  };

  const handleSubmit = () => {
    if (bannerData.name === "" || bannerData.webUrl === "") {
      setCommonAlert({ open: true, msg: t("complete_all_fields") });
      return;
    }

    const banner = {
      ...bannerData,
      sponsorId: id,
    };

    new Promise((resolve, reject) => {
      setLoading(true);

      setTimeout(() => {
        if (settings.AllowCriticalEditsAdmin) {
          dispatch(editBanner(banner, "Add"));
          navigate(`/sponsors/${id}/banners`);
          resolve();
        } else {
          setCommonAlert({ open: true, msg: t("demo_mode") });
          reject(new Error("demo mode"));
        }
      }, 600);
    })
      .catch((e) => console.error("Caught an error:", e))
      .finally(() => setLoading(false));
  };

  return loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Card className={classes.formCard}>
        <Typography variant="h5" className={classes.formTitle}>
          {t("add_banner")}
        </Typography>

        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate(`/sponsors/${id}/banners`);
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div> 

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("name")}
            </Typography>
            <TextField
              label={t("name")}
              id="name"
              margin="normal"
              value={bannerData.name}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("web_url")}
            </Typography>
            <TextField
              label={t("web_url")}
              id="webUrl"
              margin="normal"
              value={bannerData.webUrl}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.formSectionTitle}>
              {t("map_banner")}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={bannerData.map_banner}
                  onChange={handleInputChange}
                  id="map_banner"
                />
              }
              label={t("map_banner")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.submitButton}
              onClick={handleSubmit}
              variant="contained"
              fullWidth
            >
              {t("submit")}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog
        open={commonAlert.open}
        onClose={() => setCommonAlert({ open: false, msg: "" })}
      >
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default AddSponsorBanner;