import {
    FETCH_CARS,
    FETCH_CARS_SUCCESS,
    FETCH_CARS_FAILED,
    EDIT_CAR,
    FETCH_DRIVER_CARS,
    FETCH_ALL_USERS_SUCCESS
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  import { onValue, update, get, set, child, remove, push , off} from "firebase/database";
  import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
  
  export const fetchCars = (full) => (dispatch) => {
  
    const {
        carsRef
    } = firebase;
  
    dispatch({
      type: FETCH_CARS,
      payload: null
    });

    const userInfo = store.getState().auth.profile;

    off(carsRef(userInfo.uid, userInfo.usertype, false));
    onValue(carsRef(userInfo.uid, userInfo.usertype, full), snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        let str = JSON.stringify(data);
        const sizeInMB = new Blob([str], { type: "text/plain" }).size / (1024 * 1024);
        console.log("Size of fetchCars:", sizeInMB.toFixed(2) + " MB");
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_CARS_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_CARS_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_cars
        });
      }
    }, {onlyOnce: full});
  };

  export const fetchSingleCar = async (id) => {
    const {
        carEditRef
    } = firebase;
    const snapshot = await get(carEditRef(id));
    const carData = snapshot.val();
    const sizeInMB = new Blob([JSON.stringify(carData)], { type: "text/plain" }).size / (1024 * 1024);
    console.log("Size of fetchSingleCar:", sizeInMB.toFixed(2) + " MB");
    return carData;
  };

  export const fetchDriverCars = (uid) => (dispatch) => {
  
    const {
        carsRef
    } = firebase;

    onValue(carsRef(uid, 'driver', false), snapshot => {
      if (snapshot.val()) {
        let data = snapshot.val();
        let str = JSON.stringify(data);
        const sizeInMB = new Blob([str], { type: "text/plain" }).size / (1024 * 1024);
        console.log("Size of fetchDriverCars:", sizeInMB.toFixed(2) + " MB");
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_DRIVER_CARS,
          payload: arr
        });
      } 
    }, {onlyOnce: true});
  };
  
  export const editCar = (car, method) => async (dispatch) => {
    const {
      singleUserRef,
      carAddRef, 
      carEditRef,
      carImage
    } = firebase;
    dispatch({
      type: EDIT_CAR,
      payload: { method, car }
    });
    if (method === 'Add') {
        push(carAddRef, car);
    } else if (method === 'Delete') {
        remove(carEditRef(car.id));
    } else if (method === 'UpdateImage') {
      await uploadBytesResumable(carImage(car.id),car.car_image);
      let image = await getDownloadURL(carImage(car.id));
      let data = car;
      data.car_image = image;
      set(carEditRef(car.id), data);
      if(car.active && car.driver){
        update(singleUserRef(car.driver), {
          updateAt: new Date().getTime(),
          car_image: image
        });

        const usersArr = store.getState().usersdata.users;

        if(usersArr && usersArr.length > 0){
          usersArr.find((o, i) => {
              if (o.id === car.driver) {
                  usersArr[i].updateAt = new Date().getTime();
                  usersArr[i].car_image = image;
                  return true; 
              }
            });
            dispatch({
              type: FETCH_ALL_USERS_SUCCESS,
              payload: usersArr
            });
        }
      }   
    }
     else {
        set(carEditRef(car.id),car);
    }
  }

  export const updateUserCarWithImage = (newData, blob) => (dispatch) => {
    const {
      auth,
      carAddRef,
      singleUserRef,
      carImage
    } = firebase;

    var carId = push(carAddRef).key;

    uploadBytesResumable(carImage(carId),blob).then(() => {
      blob.close()
      return getDownloadURL(carImage(carId))
    }).then((url) => {
      newData.car_image = url;
      set(child(carAddRef, carId),newData )
      if(newData.active){
        let updateData = {
          carType: newData.carType,
          vehicleNumber: newData.vehicleNumber,
          vehicleMake: newData.vehicleMake,
          vehicleModel: newData.vehicleModel,
          other_info: newData.other_info,
          car_image:url,
          carApproved: newData.approved,
          updateAt: new Date().getTime()
        };
        update(singleUserRef(auth.currentUser.uid),updateData);
      }
    })
  };