import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import AlertDialog from "../components/AlertDialog";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { api } from "common";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      "&:hover fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  formCard: {
    borderRadius: "19px",
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
    maxWidth: "75vw",
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  formTitle: {
    textAlign: props => props.isRTL === 'rtl' ? "right" : "left",
    fontWeight: "bold",
    color: MAIN_COLOR,
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: MAIN_COLOR,
    color: '#FFF',
    '&:hover': {
      backgroundColor: SECONDORY_COLOR,
    },
  },
}));

const UpdateAdvertisingDetails = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editSponsor } = api;
  const sponsorsData = useSelector((state) => state.sponsorsdata.sponsors);
  const [data, setData] = useState({
    range: 0,
    points: 0,
    time: 0,
    sms_type: false,
  });
  const [sponsorData, setSponsorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const classes = useStyles({ isRTL });
  
  useEffect(() => {
    if (sponsorsData) {
      const currentSponsor = sponsorsData.find((sponsor) => sponsor.id === id);
      if (!currentSponsor) {
        navigate("/404");
        return;
      }
      setData(currentSponsor.advertisingDetails);
      setSponsorData(currentSponsor);
    }
  }, [sponsorsData, id, navigate]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "sms_type") {
      setData({ ...data, [id]: e.target.checked });
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const handleInputToNumberChange = (e) => {
    setData({ ...data, [e.target.id]: Number(e.target.value) });
  };

  const handleUpdate = () => {
      if (!data.range || data.points === 0 || data.time === 0) {
          setCommonAlert({ open: true, msg: t("complete_all_fields") });
          return;
      }

      const updatedSponsor = {...sponsorData};
      updatedSponsor.advertisingDetails = {...data};

      new Promise((resolve, reject) => {
          setLoading(true);
          setTimeout(() => {
              if (settings.AllowCriticalEditsAdmin) {
                  dispatch(editSponsor(updatedSponsor, "Update"));
                  navigate(`/sponsors/advertisingdetails/${id}`);
                  resolve();
              } else {
                  setCommonAlert({ open: true, msg: t("demo_mode") });
                  reject(new Error("Demo mode active."));
              }
          }, 600);
      })
      .catch((e) => console.error("Caught an error:", e))
      .finally(() => setLoading(false));
  };
 
  return loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Card className={classes.formCard}>
        <Typography variant="h5" className={classes.formTitle}>
          {t("update_advertising_details")}
        </Typography>

        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate(`/sponsors/advertisingdetails/${id}`);
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div> 

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("ad_range")}
            </Typography>
            <TextField
              label={t("ad_range")}
              id="range"
              margin = "normal"
              value={data.range}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
          <Typography variant="h6" className={classes.formSectionTitle}>
              {t("geolocation_points")}
            </Typography>
            <TextField
              label={t("geolocation_points")}
              id="points"
              margin = "normal"
              value={data.points}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("ad_time")}
            </Typography>
            <TextField
              label={t("ad_time")}
              id="time"
              margin = "normal"
              value={data.time}
              type="number"
              fullWidth
              onChange={handleInputToNumberChange}
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("sms_type")}
            </Typography>
            <div style={{ marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.sms_type || false}
                    onChange={handleInputChange}
                    id="sms_type"
                    name="sms_type"
                  />
                }
                label={t("sms_type")}
              />
            </div>
          </Grid>
        </Grid>

        <Button
          className={classes.submitButton}
          variant="contained"
          fullWidth
          onClick={handleUpdate}
        >
          {t("submit")}
        </Button>
      </Card>
      <AlertDialog
        open={commonAlert.open}
        onClose={() => setCommonAlert({ open: false, msg: "" })}
      >
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default UpdateAdvertisingDetails;
