import { 
    FETCH_ALL_LIST_USERS,
    FETCH_ALL_LIST_USERS_SUCCESS,
    FETCH_ALL_LIST_USERS_FAILED,
  } from "../store/types";
  
  export const INITIAL_STATE = {
    listUsers :null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const listusersreducer =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_LIST_USERS:
        return {
          ...state,
          loading:true
        };
      case FETCH_ALL_LIST_USERS_SUCCESS:
        return {
          ...state,
          listUsers :action.payload,
          loading:false
        };
      case FETCH_ALL_LIST_USERS_FAILED:
        return {
          ...state,
          listUsers: null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      default:
        return state;
    }
  };