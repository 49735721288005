import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography, TextField, Button, Grid, Card
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CircularLoading from "../components/CircularLoading";
import AlertDialog from "../components/AlertDialog";
import { makeStyles } from "@mui/styles";
import { api } from 'common';
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
      },
      "&:hover fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)', // Hover border color
      },
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR, // Border color when input is focused
      },
    },
  },
  formCard: {
    borderRadius: "19px",
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
    maxWidth: "75vw",
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  formTitle: {
    textAlign: props => props.isRTL === 'rtl' ? "right" : "left",
    fontWeight: "bold",
    color: MAIN_COLOR,
    marginBottom: theme.spacing(2),
  },
  formSectionTitle: {
    textAlign: props => props.isRTL === 'rtl' ? "right" : "left",
    fontWeight: "bold",
    color: MAIN_COLOR,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: MAIN_COLOR,
    color: '#FFF',
    '&:hover': {
      backgroundColor: SECONDORY_COLOR,
    },
  },
}));

const AddSponsor = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { editSponsor } = api;
  const settings = useSelector((state) => state.settingsdata.settings);
  const classes = useStyles({ isRTL });
  const [sponsorData, setSponsorData] = useState({
    name: "",
    email: "",
    webUrl: "",
    description: "",
    socialMediaLinks: {
      facebook: "",
      instagram: "",
      twitter: "",
      tiktok: "",
      whatsapp: "",
    },
    approved: false,
    createdAt: new Date().getTime(),
  });
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id.startsWith("social-")) {
      const socialKey = id.replace("social-", "");
      setSponsorData({
        ...sponsorData,
        socialMediaLinks: {
          ...sponsorData.socialMediaLinks,
          [socialKey]: value,
        },
      });
    } else {
      setSponsorData({ ...sponsorData, [id]: value });
    }
  };

  const handleSubmit = () => {
    if (!sponsorData.name || !sponsorData.email || !sponsorData.description) {
      setCommonAlert({ open: true, msg: t("complete_all_fields") });
    } else {
      new Promise((resolve, reject) => {
        setLoading(true);

        setTimeout(() => {
          if (settings.AllowCriticalEditsAdmin) {
            if (sponsorData.name && sponsorData.email && sponsorData.description) {
                dispatch(editSponsor(sponsorData, "Add"));
                navigate("/sponsors");
                resolve();
            } else {
              setCommonAlert({ open: true, msg: t("no_details_error") });
              reject(new Error("Please fill up all the details properly."));
            }
          } else {
            setCommonAlert({ open: true, msg: t("demo_mode") });
            reject(new Error("demo mode"));
          }
        }, 600);
      }).catch((e) => {
        console.error("Caught an error:", e);
      }).finally(() => {
        setLoading(false);
      });
    }
  }

  return loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Card className={classes.formCard}>
        <Typography variant="h5" className={classes.formTitle}>
          {t("add_sponsor")}
        </Typography>

        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/sponsors");
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div> 

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("sponsor_information")}
            </Typography>
            <TextField
              label={t("name")}
              id="name"
              margin="normal"
              value={sponsorData.name}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
            <TextField
              label={t("description")}
              id="description"
              margin="normal"
              value={sponsorData.description}
              fullWidth
              multiline
              rows={4}
              onChange={handleInputChange}
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("contact_information")}
            </Typography>
            <TextField
              label={t("email")}
              margin="normal"
              id="email"
              value={sponsorData.email}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
            <TextField
              label={t("web_url")}
              margin="normal"
              id="webUrl"
              value={sponsorData.webUrl}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("social_media_information")}
            </Typography>
            <TextField
              label={t("facebook_link")}
              id="social-facebook"
              margin="normal"
              value={sponsorData.socialMediaLinks.facebook}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            /> 
            <TextField
              label={t("instagram_link")}
              id="social-instagram"
              margin="normal"
              value={sponsorData.socialMediaLinks.instagram}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
            <TextField
              label={t("twitter_link")}
              id="social-twitter"
              margin="normal"
              value={sponsorData.socialMediaLinks.twitter}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
            <TextField
              label={t("tiktok_link")}
              id="social-tiktok"
              margin="normal"
              value={sponsorData.socialMediaLinks.tiktok}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />
            <TextField
              label={t("whatsapp_social_link")}
              id="social-whatsapp"
              margin="normal"
              value={sponsorData.socialMediaLinks.whatsapp}
              fullWidth
              onChange={handleInputChange}
              className={classes.textField}
            />          
          </Grid>

          <Grid item xs={12}>
            <Button
              className={classes.submitButton}
              onClick={handleSubmit}
              variant="contained"
              fullWidth
            >
              {t("submit")}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog
        open={commonAlert.open}
        onClose={() => setCommonAlert({ open: false, msg: "" })}
      >
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default AddSponsor;
