import {
    FETCH_BOOKING_LOCATION,
    FETCH_BOOKING_LOCATION_SUCCESS,
    FETCH_BOOKING_LOCATION_FAILED,
    STOP_LOCATION_FETCH,
    STORE_ADRESSES
} from "../store/types";
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { query, onValue, set, off, push, limitToLast } from "firebase/database";
import geohash from 'ngeohash';

export const saveTracking = (bookingId, location) => {
    const {
        trackingRef
    } = firebase;
    push(trackingRef(bookingId), location);
}

export const fetchBookingLocations = (bookingId) => (dispatch) => {

    const {
        trackingRef
    } = firebase;

    dispatch({
        type: FETCH_BOOKING_LOCATION,
        payload: bookingId,
    });
    onValue(query(trackingRef(bookingId), limitToLast(1)),(snapshot) => {
        if (snapshot.val()) {
            let data = snapshot.val();
            const locations = Object.keys(data)
                .map((i) => {
                    return data[i]
                });
            if (locations.length == 1) {
                dispatch({
                    type: FETCH_BOOKING_LOCATION_SUCCESS,
                    payload: locations[0]
                });
            }
            else {
                dispatch({
                    type: FETCH_BOOKING_LOCATION_FAILED,
                    payload: store.getState().languagedata.defaultLanguage.location_fetch_error,
                });
            }
        } else {
            dispatch({
                type: FETCH_BOOKING_LOCATION_FAILED,
                payload: store.getState().languagedata.defaultLanguage.location_fetch_error,
            });
        }
    });
};

export const stopLocationFetch = (bookingId) => (dispatch) => {

    const {
        trackingRef
    } = firebase;

    dispatch({
        type: STOP_LOCATION_FETCH,
        payload: bookingId,
    });
    off(trackingRef(bookingId));
}

export const saveUserLocation = (location) => {
    const {
      auth,
      userLocationRef,
    } = firebase;
    const uid = auth.currentUser.uid;
    location.hash = geohash.encode(location.lat, location.lng);
    location.lastUpdate = new Date().getTime();
    set(userLocationRef(uid),location);
}

export const storeAddresses = (data) => (dispatch) => {
    dispatch({
        type: STORE_ADRESSES,
        payload: data,
    });
}

// export const updateHashAllLocations = () => {
//     const {
//       allLocationsRef,
//       userLocationRef,
//     } = firebase;
//     onValue(allLocationsRef,(snapshot) => {
//         if (snapshot.val()) {
//             const users = snapshot.val();
//             let cnt = 0;
//             for (let key of Object.keys(users)) {
//                 if(users[key] && users[key].lastUpdate){
//                     let date1 = new Date(users[key].lastUpdate);
//                     let date2 = new Date();
//                     let diffTime = date2 - date1;
//                     let diffMins = diffTime / (1000 * 60);
//                     if(diffMins > 10){
//                         cnt += 1;
//                         remove(userLocationRef(key))
//                         console.log(cnt, key);
//                     }
//                 } else{
//                     cnt += 1;
//                     remove(userLocationRef(key))
//                     console.log(cnt, key);
//                 }
//             }

//         }
//     }, {onlyOnce: true});
// }


// export const updateListUsers = (users) => {
//     const {
//       driversRef,
//     } = firebase;
//     let obj = {};
//     for (let key of Object.keys(users)) {
//         if(users[key] && users[key].usertype && users[key].usertype ==='driver'){
//             obj[key] = {
//                 mobile: users[key].mobile? users[key].mobile: "",
//                 email: users[key].email? users[key].email: "",
//                 name: (users[key].firstName? users[key].firstName : "") + " " + (users[key].lastName? users[key].lastName : ""),
//                 approved: users[key].approved
//             }
//         }
//     }
//     set(driversRef, obj);
//     console.log("Updated");
// }