import { 
	FETCH_BANNERS,
	FETCH_BANNERS_SUCCESS,
	FETCH_BANNERS_FAILED,
	EDIT_BANNER,
	} from "../store/types";

export const INITIAL_STATE = {
	banners: null,
	loading: false,
	error:{
			flag:false,
			msg: null
	}
}

export const bannersreducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
			case FETCH_BANNERS:
					return {
							...state,
							loading:true
					};
			case FETCH_BANNERS_SUCCESS:
					return {
							...state,
							banners:action.payload,
							loading:false
					};
			case FETCH_BANNERS_FAILED:
					return {
							...state,
							banners:null,
							loading:false,
							error:{
									flag:true,
									msg:action.payload
							}
					};
			case EDIT_BANNER:
					return state;
			default:
					return state;
	}
};