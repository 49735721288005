import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  Card
} from "@mui/material";
import AlertDialog from "../components/AlertDialog";
import CircularLoading from "../components/CircularLoading";
import { useTranslation } from "react-i18next";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      "&:hover fieldset": {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  formCard: {
    borderRadius: "19px",
    backgroundColor: '#fff',
    padding: theme.spacing(3),
    boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
    maxWidth: "75vw",
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  formTitle: {
    textAlign: props => props.isRTL === 'rtl' ? "right" : "left",
    fontWeight: "bold",
    color: MAIN_COLOR,
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: MAIN_COLOR,
    color: '#FFF',
    '&:hover': {
      backgroundColor: SECONDORY_COLOR,
    },
  },
}));

const SponsorInfo = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const navigate = useNavigate();
  const sponsorsData = useSelector((state) => state.sponsorsdata);
  const [sponsorData, setSponsorData] = useState({
    name: "",
    email: "",
    webUrl: "",
    description: "",
    socialMediaLinks: {
      facebook: "",
      instagram: "",
      twitter: "",
      tiktok: "",
      whatsapp: "",
    },
    approved: false,
  });
  const loading = false;
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const classes = useStyles({ isRTL });
  
  useEffect(() => {
    if (sponsorsData.sponsors) {
      const currentSponsor = sponsorsData.sponsors.filter(
        (item) => item.id === id.toString()
      )[0];
      if (!currentSponsor) {
        navigate("/404");
      }
      setSponsorData(currentSponsor);
    }
  }, [sponsorsData.sponsors, id, navigate]);

  return loading ? (
    <CircularLoading />
  ) : (
    <div>
      <Card className={classes.formCard}>
        <Typography variant="h5" className={classes.formTitle}>
          {t("sponsor_info")}
        </Typography>

        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/sponsors");
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div> 

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("sponsor_information")}
            </Typography>
            <TextField
              label={t("name")}
              id="name"
              margin="normal"
              value={sponsorData.name}
              fullWidth
              disabled
              className={classes.textField}
            />
            <TextField
              label={t("description")}
              id="description"
              margin="normal"
              value={sponsorData.description}
              fullWidth
              multiline
              rows={4}
              disabled
              className={classes.textField}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("contact_information")}
            </Typography>
            <TextField
              label={t("email")}
              margin="normal"
              id="email"
              value={sponsorData.email}
              fullWidth
              disabled
              className={classes.textField}
            />
            <TextField
              label={t("web_url")}
              margin="normal"
              id="webUrl"
              value={sponsorData.webUrl}
              fullWidth
              disabled
              className={classes.textField}
            />
            <Typography variant="h6" className={classes.formSectionTitle}>
              {t("social_media_information")}
            </Typography>
            <TextField
              label={t("facebook_link")}
              id="social-facebook"
              margin="normal"
              value={sponsorData.socialMediaLinks.facebook}
              fullWidth
              disabled
              className={classes.textField}
            /> 
            <TextField
              label={t("instagram_link")}
              id="social-instagram"
              margin="normal"
              value={sponsorData.socialMediaLinks.instagram}
              fullWidth
              disabled
              className={classes.textField}
            />
            <TextField
              label={t("twitter_link")}
              id="social-twitter"
              margin="normal"
              value={sponsorData.socialMediaLinks.twitter}
              fullWidth
              disabled
              className={classes.textField}
            />
            <TextField
              label={t("tiktok_link")}
              id="social-tiktok"
              margin="normal"
              value={sponsorData.socialMediaLinks.tiktok}
              fullWidth
              disabled
              className={classes.textField}
            />
            <TextField
              label={t("whatsapp_social_link")}
              id="social-whatsapp"
              margin="normal"
              value={sponsorData.socialMediaLinks.whatsapp}
              fullWidth
              disabled
              className={classes.textField}
            />          
          </Grid>
        </Grid>
      </Card>
      <AlertDialog
        open={commonAlert.open}
        onClose={() => setCommonAlert({ open: false, msg: "" })}
      >
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default SponsorInfo;
