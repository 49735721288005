import {
	FETCH_BANNERS,
	FETCH_BANNERS_SUCCESS,
	FETCH_BANNERS_FAILED,
	EDIT_BANNER,
} from "../store/types";

import { firebase } from '../config/configureFirebase';
import { onValue, set, push, remove } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchBanners = () => (dispatch) => {
	const { bannersRef } = firebase;

	dispatch({
		type: FETCH_BANNERS,
	});

	onValue(bannersRef, (snapshot) => {
		if (snapshot.val()) {
			const data = snapshot.val()
			
			const arr = Object.keys(data).map((i) => {
				data[i].id = i;
				return data[i];
			});

			dispatch({
				type: FETCH_BANNERS_SUCCESS,
				payload: arr
			}); 
		} else {
			dispatch({
				type: FETCH_BANNERS_FAILED,
				payload: "No banners available."
			});
		}
	});
};

export const editBanner = (banner, method) => async (dispatch) => {
	const { bannersRef, bannersEditRef, bannerImage } = firebase;

	dispatch({
		type: EDIT_BANNER,
		payload: { method, banner }
	});

	if (method === 'Add') {
		push(bannersRef, banner);
	} 

	else if (method === 'Update') {
		set(bannersEditRef(banner.id), banner);
	} 
	
	else if (method === 'Delete') {
		remove(bannersEditRef(banner.id));
	}

	else if (method === 'UpdateImage') {
		await uploadBytesResumable(bannerImage(banner.id), banner.image);
		let image = await getDownloadURL(bannerImage(banner.id));
		let data = banner;
		data.image = image;
		set(bannersEditRef(banner.id), data);
	} 
};
