import {
  FETCH_DRIVERS_EARNING,
  FETCH_DRIVERS__EARNING_SUCCESS,
  FETCH_DRIVERS__EARNING_FAILED,
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue } from "firebase/database";

export const fetchDriverEarnings = (mainArr) => async (dispatch) => {
  const {
    driverEarningRef
  } = firebase;

  dispatch({
    type: FETCH_DRIVERS_EARNING,
    payload: null,
  });
  onValue(driverEarningRef, (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data)
        .map((i) => {
          return data[i];
        });
      dispatch({
        type: FETCH_DRIVERS__EARNING_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_DRIVERS__EARNING_FAILED,
        payload: "No report data"
      });
    }
  });
};

