import {
  FETCH_BOOKING_DISCOUNT,
  FETCH_BOOKING__DISCOUNT_SUCCESS,
  FETCH_BOOKING__DISCOUNT_FAILED,
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue } from "firebase/database";

export const fetchEarningsReport = () => async (dispatch) => {

  const {
    adminEarningRef,
  } = firebase;
  
  dispatch({
    type: FETCH_BOOKING_DISCOUNT,
    payload: null
  });
  onValue(adminEarningRef, (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val();
      const arr = Object.keys(data)
        .map((i) => {
          return data[i];
        });
      dispatch({
        type: FETCH_BOOKING__DISCOUNT_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_BOOKING__DISCOUNT_FAILED,
        payload: "No data available."
      });
    }
  });
}

