import {
    FETCH_STATE,
    FETCH_STATE_SUCCESS,
    FETCH_STATE_FAILED,
    EDIT_STATE
  } from "../store/types";
  import { firebase } from '../config/configureFirebase';
  import store from '../store/store';
  import { onValue, push, set, remove } from "firebase/database";
  
  export const fetchState = () => (dispatch) => {
 
    const {
      stateRef
    } = firebase;
  
    dispatch({
      type: FETCH_STATE,
      payload: null,
    });
    onValue(stateRef, (snapshot) => {
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_STATE_SUCCESS,
          payload: arr.reverse()
        });
      } else {
        dispatch({
          type: FETCH_STATE_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_states,
        });
      }
    });
  };
  
  export const editState = (reasons, method) => (dispatch) => {

    const {
      stateRef,
        editStateRef
    } = firebase;
  
    dispatch({
      type: EDIT_STATE,
      payload: {method, reasons}
    });
    
    if (method === 'Add') {
      push(stateRef, reasons);
    }  else if (method === 'Delete') {
      remove(editStateRef(reasons.id));
    }
    else if(method === 'Update'){
      set(editStateRef(reasons.id),{ ...reasons });
    }
  }
  
  