export const FirebaseConfig = {
	"projectId": "dracurider-1fcb8",
	"appId": "1:99575774944:web:45671a7dc9d7a28e7a9a85",
	"databaseURL": "https://dracurider-1fcb8-default-rtdb.firebaseio.com",
	"storageBucket": "dracurider-1fcb8.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAD2zai3QZn-pGXA3mcY4wSvWy7O3CIlrg",
	"authDomain": "dracurider-1fcb8.firebaseapp.com",
	"messagingSenderId": "99575774944",
	"measurementId": "G-PHZ95ZXVLX"
};