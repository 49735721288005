import {
  FETCH_SPONSORS,
  FETCH_SPONSORS_SUCCESS,
  FETCH_SPONSORS_FAILED,
  EDIT_SPONSOR,
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue, set, push, remove } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const fetchSponsors = () => async (dispatch) => {
  const { sponsorsRef } = firebase;
  //console.log('sponsors', sponsorsRef);
  dispatch({
    type: FETCH_SPONSORS,
    payload: null
  });
  
  onValue(sponsorsRef, (snapshot) => {
    if (snapshot.val()) {
      const data = snapshot.val()
      
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        return data[i];
      });

      dispatch({
        type: FETCH_SPONSORS_SUCCESS,
        payload: arr
      }); 
    } else {
      dispatch({
        type: FETCH_SPONSORS_FAILED,
        payload: "No sponsors available."
      });
    }
  });
};

export const editSponsor = (sponsor, method) => async (dispatch) => {
  const { sponsorsRef, sponsorsEditRef, sponsorImage, sponsorMapIconImage } = firebase;

  dispatch({
    type: EDIT_SPONSOR,
    payload: { method, sponsor }
  });

  if (method === 'Add') {
    push(sponsorsRef, sponsor);
  } 

  else if (method === 'Update') {
    set(sponsorsEditRef(sponsor.id), sponsor);
  } 
  
  else if (method === 'Delete') {
    remove(sponsorsEditRef(sponsor.id));
  } 
  
  else if (method === 'UpdateImage') {
    await uploadBytesResumable(sponsorImage(sponsor.id), sponsor.image);
    let image = await getDownloadURL(sponsorImage(sponsor.id));
    let data = sponsor;
    data.image = image;
    set(sponsorsEditRef(sponsor.id), data);
  } 
  
  else if (method === 'UpdateMapIconImage') {
    await uploadBytesResumable(sponsorMapIconImage(sponsor.id), sponsor.advertisingDetails.mapIcon);
    let mapIcon = await getDownloadURL(sponsorMapIconImage(sponsor.id));
    let data = sponsor;
    data.advertisingDetails.mapIcon = mapIcon;
    set(sponsorsEditRef(sponsor.id), data);
  }
};
