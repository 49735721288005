import { 
	FETCH_SPONSORS,
	FETCH_SPONSORS_SUCCESS,
	FETCH_SPONSORS_FAILED,
	EDIT_SPONSOR,
	} from "../store/types";

export const INITIAL_STATE = {
	sponsors: null,
	loading: false,
	error:{
			flag:false,
			msg: null
	}
}

export const sponsorsreducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
			case FETCH_SPONSORS:
					return {
							...state,
							loading:true
					};
			case FETCH_SPONSORS_SUCCESS:
					return {
							...state,
							sponsors:action.payload,
							loading:false
					};
			case FETCH_SPONSORS_FAILED:
					return {
							...state,
							sponsors:null,
							loading:false,
							error:{
									flag:true,
									msg:action.payload
							}
					};
			case EDIT_SPONSOR:
					return state;
			default:
					return state;
	}
};