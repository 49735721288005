import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Typography, Button, Modal, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { colors } from "../components/Theme/WebTheme";
import { sponsorColumns, SECONDORY_COLOR, MAIN_COLOR } from "common/sharedFunctions";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import AlertDialog from "../components/AlertDialog";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function AdvertisingDetails() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { editSponsor } = api;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams(); 
  const rootRef = useRef(null);
  const classes = useStyles();
  const sponsorsData = useSelector((state) => state.sponsorsdata.sponsors);
  const [data, setData] = useState([]);
  const [sponsorData, setSponsorData] = useState(null);
  const [locationData, setLocationData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageData, setImageData] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loading, setLoading] = useState(false);

  const onClick = (rowData) => {
    setImageData(rowData.mapIcon);
    setProfileModal(true);
  };

  const columns = sponsorColumns(t, isRTL, onClick);

  const subcolumns = [
    { title: t("location_name"), field: "name" },
    { title: t("latitude"), field: "latitude", type: "numeric" },
    { title: t("longitude"), field: "longitude", type: "numeric" },
  ];

  useEffect(() => {
    if (sponsorsData) {
      const currentSponsor = sponsorsData.filter((sponsor) => sponsor.id === id.toString())[0];
      if (!currentSponsor) {
        navigate("/404");
      } else {
        setSponsorData(currentSponsor);
        if (currentSponsor.advertisingDetails) {
          setData([currentSponsor.advertisingDetails]);
        } else {
          setData([]);
        }
      }
    } else {
      setData([]);
    }
  }, [sponsorsData, id, navigate]);

  useEffect(() => {
    if (sponsorData && sponsorData.advertisingDetails && sponsorData.advertisingDetails.locations) {
      setLocationData(sponsorData.advertisingDetails.locations);
    } else {
      setLocationData([]);
    }
  }, [sponsorData]);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handleDeleteAdvertisingDetails = async () => {
    if (sponsorData) {
      const updatedSponsor = { ...sponsorData };
      updatedSponsor.advertisingDetails = null;
      dispatch(editSponsor(updatedSponsor, "Update"));
      setData([]);
      navigate("/sponsors");
    }
  };

  const handleUpdateLocations = (updatedLocations) => {
    if (sponsorData) {
      const updatedSponsor = { ...sponsorData };
      updatedSponsor.advertisingDetails.locations = updatedLocations;
      dispatch(editSponsor(updatedSponsor, "Update")); 
    }
  };

  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  };

  const handleSetProfileModal = (e) => {
    e.preventDefault();
    if (selectedImage) {
      setLoading(true);
      let updatedSponsor = { ...sponsorData };
      updatedSponsor.advertisingDetails.mapIcon = selectedImage;
      dispatch(editSponsor(updatedSponsor, "UpdateMapIconImage"));
      setProfileModal(false);
      setTimeout(() => {
        setSelectedImage(null);
        setLoading(false);
      }, 10000);
    } else {
      setCommonAlert({ open: true, msg: t("choose_image_first") });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return loading ? (
    <CircularLoading />
  ) : (
    <div ref={rootRef}>
      <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/sponsors");
            }}
          >
            <Typography
              style={{
                marginBottom: 10,
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div> 
      <MaterialTable
        title={t("advertising_details")}
        columns={columns}
        data={data}
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
          border: "1px solid rgba(224, 224, 224, 1)",
        }}
        onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
        options={{
          pageSize: 10,
          pageSizeOptions: [10, 15, 20],
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor: selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
            border: "1px solid rgba(224, 224, 224, 1)",
          }),
          editable: {
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
          cellStyle: {
            border: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("create_advertising_details"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                handleDeleteAdvertisingDetails();
                resolve();
              }, 600);
            }),
        }}
        actions={[
          data.length === 0 ? {
            icon: 'add',
            tooltip: t("add_advertising_details"),
            isFreeAction: true,
            onClick: () => navigate(`/sponsors/addadvertisingdetails/${id}`),
          } : null,
          {
            icon: () => (
              <div style={{display:"flex", alignItems:"center" , flexWrap :"wrap", padding:10, backgroundColor:MAIN_COLOR, borderRadius:5, boxShadow: "0px 3px 5px 0px #B4B4B3"}}>
                <Typography style={{color:colors.LandingPage_Background}}>
                {t("locations")}
                </Typography>
                </div>
            ),
            tooltip:t('locations'),
            onClick:()=> setOpen(true),
          },
          {
            icon: "edit",
            tooltip: t("edit"),
            onClick: () => navigate(`/sponsors/updateadvertisingdetails/${id}`),
          },
        ]}
      />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
              {t("upload_map_icon")}

              <input
                type="file"
                style={{ marginLeft: 10 }}
                name={t("image")}
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage && !loading ? (
              <Tooltip title={t("cancel")}>
                <CancelIcon
                  onClick={() => setSelectedImage(null)}
                  style={{
                    fontSize: 30,
                    backgroundColor: "red",
                    borderRadius: 50,
                    color: "white",
                  }}
                />
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage ? (
              <img
                alt="not fount"
                width={"200px"}
                height={"200px"}
                src={URL.createObjectURL(selectedImage)}
                style={{ marginTop: 15, marginBottom: 20 }}
              />
            ) : (
              <img
                alt="not fount"
                width={"200px"}
                height={"200px"}
                src={imageData}
                style={{ marginTop: 10 }}
              />
            )}
            <br />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleProfileModal}
                  variant="contained"
                  style={{ backgroundColor:colors.RED }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetProfileModal}
                  variant="contained"
                  style={{ marginLeft: 10, backgroundColor:colors.GREEN }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open} 
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={
              t("location_details")
            }
            columns={subcolumns}
            data={locationData}
            options={{
              exportButton: true,
            }}
            editable={{
              onRowAdd: (newData) => new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (settings.AllowCriticalEditsAdmin) {
                    const updatedLocations = [...locationData, newData];
                    setLocationData(updatedLocations);
                    handleUpdateLocations(updatedLocations);
                    resolve();
                  } else {
                    alert(t("demo_mode"));
                    reject();
                  }
                }, 600);
              }),
              onRowUpdate: (newData, oldData) => new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (settings.AllowCriticalEditsAdmin) {
                    const index = locationData.indexOf(oldData);
                    const updatedLocations = [...locationData];
                    if (index > -1) {
                      updatedLocations[index] = newData;
                      setLocationData(updatedLocations);
                      handleUpdateLocations(updatedLocations);
                      resolve();
                    } else {
                      reject();
                    }
                  } else {
                    alert(t("demo_mode"));
                    reject();
                  }
                }, 600);
              }),
              onRowDelete: oldData => new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (settings.AllowCriticalEditsAdmin) {
                    const updatedLocations = locationData.filter(location => location !== oldData);
                    setLocationData(updatedLocations);
                    handleUpdateLocations(updatedLocations);
                    resolve();
                  } else {
                    alert(t("demo_mode"));
                    reject();
                  }
                }, 600);
              })
            }}
          />
        </div>
      </Modal>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  ); 
}
