import { 
    FETCH_ALL_DRIVERS,
    FETCH_ALL_DRIVERS_SUCCESS,
    FETCH_ALL_DRIVERS_FAILED
  } from "../store/types";
  
  export const INITIAL_STATE = {
    drivers: null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }
  
  export const activedriversreducer =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_ALL_DRIVERS:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ALL_DRIVERS_SUCCESS:
        return {
          ...state,
          loading: false,
          drivers:action.payload
        };
      case FETCH_ALL_DRIVERS_FAILED:
        return {
          ...state,
          drivers:null,
          loading: false,
          error:{
            flag:true,
            msg:action.payload
          }
        };  
      default:
        return state;
    }
  };