import {
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  EDIT_USER,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILED,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  FETCH_ALL_LIST_USERS,
  FETCH_ALL_LIST_USERS_SUCCESS,
  FETCH_ALL_LIST_USERS_FAILED,
  USER_DELETED,
  FETCH_ALL_DRIVERS,
  FETCH_ALL_DRIVERS_SUCCESS,
  FETCH_ALL_DRIVERS_FAILED,
  FETCH_ALL_USERS_STATIC,
  FETCH_ALL_USERS_STATIC_SUCCESS,
  FETCH_ALL_USERS_STATIC_FAILED
} from "../store/types";
import { firebase } from '../config/configureFirebase';
import { onValue, set, push, update, off, get, remove, query, startAt, endAt, orderByChild } from "firebase/database";
import { uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { signOut } from "firebase/auth";
import store from "../store/store";
import { getGeohashRange } from "../other/GeoFunctions";
//import { updateListUsers } from "../actions/locationactions";

export const fetchUsers = (full) => (dispatch) => {

  const {
    usersRef,
  } = firebase;

  dispatch({
    type: FETCH_ALL_USERS,
    payload: null
  });

  const profile = store.getState().auth.profile;

  off(usersRef(profile.uid, profile.usertype,full));
  onValue(usersRef(profile.uid, profile.usertype, full), async snapshot => {
    if (snapshot.val()) {
      const data = snapshot.val();
      let str = JSON.stringify(data);
      const arr = Object.keys(data)
      .map(i => {
        data[i].id = i;
        return data[i];
      });
      const sizeInMB = new Blob([str], { type: "text/plain" }).size / (1024 * 1024);
      console.log("Size of fetchUsers:", arr.length, sizeInMB.toFixed(2) + " MB");
      // if(full){
      //   updateListUsers(data);
      // }
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: arr
      });
    } else {
      dispatch({
        type: FETCH_ALL_USERS_FAILED,
        payload: "No users available."
      });
    }
  },{onlyOnce: full});
};

export const fetchUsersOnce = () => (dispatch) => {

  const {
    usersRef,
    allLocationsRef
  } = firebase;

  dispatch({
    type: FETCH_ALL_USERS_STATIC,
    payload: null
  });
  onValue(usersRef, async snapshot => {
    if (snapshot.val()) {
      const locationdata = await get(allLocationsRef);
      const locations = locationdata.val();
      const data = snapshot.val();
      const arr = Object.keys(data)
      .map(i => {
        data[i].id = i;
        data[i].location = locations && locations[i] ? locations[i] : null;
        return data[i];
      });
      dispatch({
        type: FETCH_ALL_USERS_STATIC_SUCCESS,
        payload: arr
      })
    } else {
      dispatch({
        type: FETCH_ALL_USERS_STATIC_FAILED,
        payload: "No users available."
      });
    }
  },{onlyOnce: true});
  
};

export const fetchUsersList = () => (dispatch) => {

  const {
    listUserRef
  } = firebase;

  const role = store.getState().auth.profile.usertype;

  dispatch({
    type: FETCH_ALL_LIST_USERS,
    payload: null
  });
  if(role === 'admin' || role === 'fleetadmin'){
    onValue(listUserRef, async snapshot => {
      if (snapshot.val()) {
        const data = snapshot.val();
        let str = JSON.stringify(data);
        const sizeInMB = new Blob([str], { type: "text/plain" }).size / (1024 * 1024);
        console.log("Size of fetchUsersList:", sizeInMB.toFixed(2) + " MB");
        dispatch({
          type: FETCH_ALL_LIST_USERS_SUCCESS,
          payload: data
        })
      } else {
        dispatch({
          type: FETCH_ALL_LIST_USERS_FAILED,
          payload: "No users available."
        });
      }
    },{onlyOnce: true});
  } else {
    dispatch({
      type: FETCH_ALL_LIST_USERS_FAILED,
      payload: "No users available."
    });
  }
  
};

export const fetchDrivers = () => async(dispatch) => {

  const {
    singleUserRef,
    allLocationsRef,
    settingsRef,
  } = firebase;

  const settingsdata = await get(settingsRef);
  const settings = settingsdata.val();

  dispatch({
    type: FETCH_ALL_DRIVERS,
    payload: null
  });

  const userLocation = store.getState().tripdata.pickup;

  if(!(userLocation && userLocation.lat)){
    dispatch({
      type: FETCH_ALL_DRIVERS_FAILED,
      payload: "GPS Failed."
    });
    return;
  }

  const distance = settings.driverRadius ? (parseFloat(settings.driverRadius) / 1.609344 ) : 5;
  const { lowerGeohash, upperGeohash } = getGeohashRange(userLocation.lat, userLocation.lng, distance);
  const snapshot = await get(query(allLocationsRef, orderByChild('hash'), startAt(lowerGeohash), endAt(upperGeohash)))
  const activeUsers = snapshot.val();
  const oldData = store.getState().usersdata.drivers;
  let arr = [];
  let str = "";
  str += JSON.stringify(activeUsers);
  for(let key in activeUsers){
    if(activeUsers[key].lastUpdate && activeUsers[key].hash){
    let obj;
    if(oldData && oldData.length>0){
      for(let i = 0; i <oldData.length; i++){
        if(oldData[i].id === key){
          obj = oldData[i]; 
          break;
        }
      }
    }
    if(obj && obj.location){
        arr.push({
          id: key,
          location: {lat: activeUsers[key].lat, lng: activeUsers[key].lng},
          carType: obj.carType,
          vehicleNumber: obj.vehicleNumber,
          firstName: obj.firstName,
          lastName: obj.lastName,
          queue: obj.queue,
          pushToken: obj.pushToken,
          fleetadmin: obj.fleetadmin
        });
      } else{
        const userSnap = await get(singleUserRef(key));
        const data = userSnap.val();
        str += JSON.stringify(data);
        if(data && data.approved == true  && ( (data.licenseImage && settings.license_image_required) || !settings.license_image_required)
        && (((data.carApproved && settings.carType_required) || !settings.carType_required) || !settings.carType_required) && ((data.term && settings.term_required) || !settings.term_required) ){
          arr.push({
            id: key,
            location: {lat: activeUsers[key].lat, lng: activeUsers[key].lng},
            carType: data.carType ? data.carType : null,
            vehicleNumber:  data.vehicleNumber ? data.vehicleNumber : null,
            firstName: data.firstName,
            lastName: data.lastName,
            queue: data.queue,
            pushToken: data.pushToken,
            fleetadmin: data.fleetadmin? data.fleetadmin: null
          });
        }
      }
    }
  }

  const sizeInMB = new Blob([str], { type: "text/plain" }).size / (1024 * 1024);
  console.log("Size of App Drivers:", arr.length, sizeInMB.toFixed(2) + " MB");

  if(arr.length> 0){
    dispatch({
      type: FETCH_ALL_DRIVERS_SUCCESS,
      payload: arr
    });
  } else {
    dispatch({
      type: FETCH_ALL_DRIVERS_FAILED,
      payload: "No users available."
    });
  }
};

export const fetchDriversWeb = () => async(dispatch) => {

  const {
    singleUserRef,
    allLocationsRef,
    settingsRef,
  } = firebase;

  const settingsdata = await get(settingsRef);
  const settings = settingsdata.val();

  dispatch({
    type: FETCH_ALL_DRIVERS,
    payload: null
  });

  const snapshot = await get(allLocationsRef);
  const activeUsers = snapshot.val();
  const oldData = store.getState().usersdata.drivers;
  let arr = [];
  let str = "";

  str += JSON.stringify(activeUsers);
  for(let key in activeUsers){
    if(activeUsers[key].lastUpdate && activeUsers[key].hash){
      let obj;
      if(oldData && oldData.length>0){
        for(let i = 0; i <oldData.length; i++){
          if(oldData[i].id === key){
            obj = oldData[i]; 
            break;
          }
        }
      }
      if(obj && obj.location){
        arr.push({
          id: key,
          location: {lat: activeUsers[key].lat, lng: activeUsers[key].lng},
          carType: obj.carType,
          vehicleNumber: obj.vehicleNumber,
          firstName: obj.firstName,
          lastName: obj.lastName,
          queue: obj.queue,
          pushToken: obj.pushToken,
          fleetadmin: obj.fleetadmin
        });
      } else{
        const userSnap = await get(singleUserRef(key));
        const data = userSnap.val();
        str += JSON.stringify(data);
        if(data && data.approved == true && ( (data.licenseImage && settings.license_image_required) || !settings.license_image_required)
        && (((data.carApproved && settings.carType_required) || !settings.carType_required) || !settings.carType_required) && ((data.term && settings.term_required) || !settings.term_required) ){
          arr.push({
            id: key,
            location: {lat: activeUsers[key].lat, lng: activeUsers[key].lng},
            carType: data.carType ? data.carType : null,
            vehicleNumber:  data.vehicleNumber ? data.vehicleNumber : null,
            firstName: data.firstName,
            lastName: data.lastName,
            queue: data.queue,
            pushToken: data.pushToken,
            fleetadmin: data.fleetadmin? data.fleetadmin: null,
            usertype: data.usertype? data.usertype: null
          });
        }
      }
    }
  }

  const sizeInMB = new Blob([str], { type: "text/plain" }).size / (1024 * 1024);
  console.log("Size of Realtime Drivers:", arr.length, sizeInMB.toFixed(2) + " MB");

  if(arr.length> 0){
    dispatch({
      type: FETCH_ALL_DRIVERS_SUCCESS,
      payload: arr
    });
  } else {
    dispatch({
      type: FETCH_ALL_DRIVERS_FAILED,
      payload: "No users available."
    });
  }
};

export const addUser = (userdata) => (dispatch) => {
  const {
    usersAddRef
  } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: userdata
  });

  delete userdata.tableData;

  push(usersAddRef, userdata).then(() => {
    dispatch({
      type: EDIT_USER_SUCCESS,
      payload: null
    });
  }).catch((error) => {
    dispatch({
      type: EDIT_USER_FAILED,
      payload: error
    });
  });
}

export const editUser = (id, user) => (dispatch) => {

  const {
    singleUserRef
  } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: user
  });
  let editedUser = user;
  delete editedUser.tableData;
  set(singleUserRef(id), editedUser);

  const usersArr = store.getState().usersdata.users;

  if(usersArr && usersArr.length > 0){
    let newArray = usersArr.slice();
    usersArr.find((o, i) => {
        if (o.id === id) {
            newArray[i] = editedUser;
            return true; 
        }
      });
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: newArray
      });
  }
  
}

export const updateUserCar = (id, data) => (dispatch) => {
  const {
    singleUserRef
  } = firebase;

  dispatch({
    type: EDIT_USER,
    payload: data  
  });
  update(singleUserRef(id),data);

  const usersArr = store.getState().usersdata.users;

  if(usersArr && usersArr.length > 0){
    usersArr.find((o, i) => {
        if (o.id === id) {
            usersArr[i] = {...usersArr[i], ...data};
            return true; 
        }
      });
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: usersArr
      });
  }
}

export const updateLicenseImage = (uid, imageBlob, imageType) => async (dispatch) => {

  const {
    singleUserRef,
    driverDocsRef,
    driverDocsRefBack,
    verifyIdImageRef
  } = firebase;

  let profile = {};
  if(imageType === 'licenseImage'){
    await uploadBytesResumable(driverDocsRef(uid),imageBlob);
    let image = await getDownloadURL(driverDocsRef(uid));
    profile.licenseImage = image;
  }
  if(imageType === 'licenseImageBack'){
    await uploadBytesResumable(driverDocsRefBack(uid),imageBlob);
    let image1 = await getDownloadURL(driverDocsRefBack(uid));
    profile.licenseImageBack = image1;
  }
  if(imageType === 'verifyIdImage'){
    await uploadBytesResumable(verifyIdImageRef(uid),imageBlob);
    let image1 = await getDownloadURL(verifyIdImageRef(uid));
    profile.verifyIdImage = image1;
  }
  update(singleUserRef(uid),profile);
  dispatch({
    type: EDIT_USER,
    payload: uid
  });

  const usersArr = store.getState().usersdata.users;

  if(usersArr && usersArr.length > 0){
    usersArr.find((o, i) => {
        if (o.id === uid) {
            usersArr[i] = {...usersArr[i], ...profile};
            return true; 
        }
      });
      dispatch({
        type: FETCH_ALL_USERS_SUCCESS,
        payload: usersArr
      });
  }

};

export const deleteUser = (uid) => (dispatch) => {

  const {
    auth,
    walletHistoryRef,
    singleUserRef,
    userNotificationsRef,
    carsRef,
    carEditRef
  } = firebase;

  dispatch({
    type: DELETE_USER,
    payload: uid
  });

  if (auth.currentUser.uid === uid) {
    off(singleUserRef(uid));
    off(walletHistoryRef(uid));
    off(userNotificationsRef(uid));
  }

  onValue(singleUserRef(uid), userdata => {
    const profile = userdata.val();
    if(profile.usertype === 'driver'){
      onValue(carsRef(uid, 'driver', false), carssnapshot => {
        let cars = carssnapshot.val();
        if (cars) {
          const arr = Object.keys(cars);
          for(let i = 0; i < arr.length; i++){
            remove(carEditRef(arr[i]));
          }
        }
      });
    } 
    
    remove(singleUserRef(uid)).then(() => {
      if (auth.currentUser.uid === uid) {
        signOut(auth);
        dispatch({
          type: USER_DELETED,
          payload: null
        });
      } else {
        remove(singleUserRef(uid)).then(() => {
          dispatch({
            type: DELETE_USER_SUCCESS,
            payload: null
          });
        }).catch((error) => {
          dispatch({
            type: DELETE_USER_FAILED,
            payload: error
          });
        });
      }
    });
  },{onlyOnce:true});
}

export const fetchSingleUser = async (uid) => {
  const { singleUserRef } = firebase;
  const userProfile = await get(singleUserRef(uid));
  return userProfile.val();
};